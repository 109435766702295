var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticClass:"accent white--text",attrs:{"height":"auto"}},[(_vm.isHome)?_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('nuxt-link',{staticClass:"d-flex",attrs:{"to":"/"}},[_c('img',{staticClass:"text-xs-center",staticStyle:{"flex":"none !important"},attrs:{"src":_vm.$store.state.brand.brand.logo,"height":"48px","alt":"Logo"}})]),_vm._v(" "),_c('ul',{staticClass:"pl-0 mt-3 subheading"},[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('terms')))])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('privacy')))])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_vm.$store.state.brand.brand.city_url
                  ? _vm.$store.state.brand.brand.city_url
                  : '#',"target":"_blank"}},[_vm._v("\n              "+_vm._s(this.$store.state.brand.brand.city_name)+"\n            ")])])])],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('h2',{staticClass:"font-weight-bold",staticStyle:{"font-size":"19px"}},[_vm._v("\n          "+_vm._s(_vm.$t('about'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"subheading"},[_vm._v("\n          Este sitio es una prueba piloto de la Lotería de Rio Negro,\n          demostrando el potencial de certificar los resultados de los sorteos\n          en blockchain. Buscamos elevar los niveles de transparencia y\n          control de la ciudadanía.\n        ")])]),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('h2',{staticClass:"font-weight-bold",staticStyle:{"font-size":"19px"}},[_vm._v("\n          "+_vm._s(_vm.$t('contact'))+"\n        ")]),_vm._v(" "),_c('v-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.sendEmailContact($event)}}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"outline":"","label":"Nombre *","required":"","hide-details":""},model:{value:(_vm.contact.name),callback:function ($$v) {_vm.$set(_vm.contact, "name", $$v)},expression:"contact.name"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"outline":"","label":"Email *","required":"","hide-details":""},model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"outline":"","rows":"3","label":"Mensaje *","required":"","hide-details":""},model:{value:(_vm.contact.message),callback:function ($$v) {_vm.$set(_vm.contact, "message", $$v)},expression:"contact.message"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"text-xs-right",attrs:{"xs12":""}},[_c('v-btn',{staticClass:"mt-0 mr-0",attrs:{"disabled":_vm.$wait.any ||
                    !_vm.contact.message ||
                    !_vm.contact.email ||
                    !_vm.contact.name ||
                    !_vm.email_contact,"outline":"","color":"white","type":"submit"}},[_vm._v("Enviar")])],1)],1)],1)],1)],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":"","mt-3":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","text-xs-center":"","text-sm-left":""}},[_c('h4',[_c('a',{staticClass:"white--text",attrs:{"href":_vm.$store.state.brand.brand.city_url
                ? _vm.$store.state.brand.brand.city_url
                : '#',"target":"_blank"}},[_vm._v("\n            © "+_vm._s(new Date().getFullYear())+" Gobierno de\n            "+_vm._s(this.$store.state.brand.brand.city_name)+"\n          ")])])]),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","sm6":"","text-xs-center":"","text-sm-right":""}},[_c('h4',[_c('a',{staticClass:"white--text",attrs:{"href":"https://os.city","target":"_blank"}},[_c('i',{staticClass:"material-icons bottom"},[_vm._v("flash_on")]),_vm._v(" by OS City\n          ")])])])],1)],1):_c('v-container',{attrs:{"pa-3":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","text-xs-center":"","text-sm-left":""}},[_c('h4',[_c('a',{staticClass:"white--text",attrs:{"href":_vm.$store.state.brand.brand.city_url
                ? _vm.$store.state.brand.brand.city_url
                : '#',"target":"_blank"}},[_vm._v("\n            © "+_vm._s(new Date().getFullYear())+" Gobierno de Rio Negro\n          ")])])]),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","sm6":"","text-xs-center":"","text-sm-right":""}},[_c('h4',[_c('a',{staticClass:"white--text",attrs:{"href":"https://os.city","target":"_blank"}},[_c('i',{staticClass:"material-icons bottom"},[_vm._v("flash_on")]),_vm._v(" by OS City\n          ")])])])],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"top":true,"right":true,"timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color,"multi-line":_vm.snackbar.mode === 'multi-line',"vertical":_vm.snackbar.mode === 'vertical'},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v("\n    "+_vm._s(_vm.snackbar.text)+"\n    "),_c('v-btn',{attrs:{"dark":"","flat":""},nativeOn:{"click":function($event){_vm.snackbar.show = false}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }