import BrandService from '@/services/BrandService'

export const state = () => ({
  brand: {
    app_name: 'OS City',
    city_name: 'OS City',
    city_slogan: "Improve your government's efficiency and trust",
    city_splash_primary:
      'https://firebasestorage.googleapis.com/v0/b/playground-gcp-201118.appspot.com/o/brand%2F28ef7b6d-b9d3-4043-8960-b389c2ff2f7c?alt=media&token=df7a17ca-77b6-4a14-bfff-f6e71856b6cf',
    city_splash_secondary:
      'https://firebasestorage.googleapis.com/v0/b/playground-gcp-201118.appspot.com/o/brand%2Fc836f8b1-f012-4dc1-8f5f-498b03d3b155?alt=media&token=4d0a9a9d-3f4f-4d2b-9042-4631fb0ef51e',
    city_splash_login: '',
    city_url: 'demo.os.city',
    favicon:
      'https://firebasestorage.googleapis.com/v0/b/playground-gcp-201118.appspot.com/o/brand%2F313872e7-ab56-43a2-a87e-19c778de4364?alt=media&token=fb912827-0c5d-4e2b-bfb1-6553c904f41a',
    logo:
      'https://firebasestorage.googleapis.com/v0/b/playground-gcp-201118.appspot.com/o/brand%2F3086e769-ad7b-43a7-84d2-11ce2a220e21?alt=media&token=bb166f24-156f-4393-a67c-b0cce7a797ad',
    primary_color: '#e5528e',
    secondary_color: '#ad57b2',
    additional_color: '#d6d6d6'
  }
})

export const mutations = {
  SET_BRAND(state, brand) {
    state.brand = brand
  }
}

export const actions = {
  async get({ commit }) {
    const { result } = await BrandService.get()
    if (result && result.length > 0) {
      commit('SET_BRAND', result[0])
    }
  }
}
