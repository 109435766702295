var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"pb-5 index-superior",attrs:{"value":_vm.value,"temporary":_vm.$vuetify.breakpoint.smAndDown,"app":"","dark":"","width":"300","disable-resize-watcher":""},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-list',{staticClass:"menu-sidebar"},[(_vm.$auth.loggedIn)?[(_vm.$store.state.auth.loggedIn && _vm.$store.state.auth.user.first_name)?_c('v-list-tile',{attrs:{"to":"/profile","avatar":""}},[_c('div',{staticClass:"flex-img-sidebar"},[_c('v-avatar',{staticClass:"person-sidebar",attrs:{"size":"65","color":"primary"}},[(_vm.$store.state.auth.user.picture)?_c('img',{attrs:{"src":_vm.$store.state.auth.user.picture,"alt":"Avatar"}}):(_vm.$store.state.auth.user.first_name)?_c('span',{staticClass:"white--text headline"},[_vm._v("\n              "+_vm._s(_vm.$store.state.auth.user.first_name.charAt(0))+"\n            ")]):_vm._e()]),_vm._v(" "),_c('div',[_c('span',{staticClass:"user-name text-xs-center"},[_vm._v("\n              "+_vm._s(_vm.$store.state.auth.user.first_name)+"\n            ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"user-role text-xs-center text-capitalize"},[_vm._v("\n              "+_vm._s(_vm.$store.state.auth.user.role &&
                _vm.$store.state.auth.user.role.name
                  ? _vm.$store.state.auth.user.role.name
                  : _vm.$store.state.auth.user.type_operator
                  ? _vm.$store.state.auth.user.type_operator[0]
                  : null)+"\n            ")])])],1)]):_vm._e(),_vm._v(" "),(_vm.setDashboard)?_c('v-list-tile',{staticClass:"main-menu-item border-top-menu-item",style:({ color: '#fff' }),attrs:{"to":"/admin/dashboard","active-class":"primary"}},[_c('v-list-tile-action',{staticClass:"align-items-center"},[_c('v-icon',[_vm._v("dashboard")])],1),_vm._v(" "),_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"text-xs-left"},[_vm._v("\n            Dashboard\n          ")])],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-elements-sidebar"},[(_vm.itemsModules.length > 0)?_vm._l((_vm.itemsModules),function(item,i){return _c('v-list-group',{key:(i + "-" + (item.title)),class:{
              'arrow-none': item.title || item.svg
            },attrs:{"group":item.group,"no-action":""}},[(item.items.length !== 0)?_c('v-list-tile',{staticClass:"main-menu-item",attrs:{"slot":"activator"},slot:"activator"},[(item.action)?_c('v-icon',[_vm._v("\n                "+_vm._s(item.action)+"\n              ")]):_vm._e(),_vm._v(" "),(item.svg)?_c('v-img',{staticClass:"ml-3 mr-3",attrs:{"src":require(("@/assets/svgs/" + (item.svg))),"contain":true,"height":"30px","width":"30px"}}):_vm._e(),_vm._v(" "),_c('v-list-tile-title',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.title))])],1):_vm._e(),_vm._v(" "),_vm._l((item.items),function(subItem,j){return [(
                  subItem.title === 'Ciudadano' ||
                    (subItem.title === 'Administrador' &&
                      _vm.$auth.user.type &&
                      _vm.$auth.user.type === 'operator') ||
                    subItem.title === 'Ciudadano' ||
                    subItem.title === 'Administrador' ||
                    (subItem.title === 'Operador' &&
                      _vm.$auth.user.type &&
                      _vm.$auth.user.type === 'superadmin')
                )?[_c('v-list',{key:(j + "-" + (subItem.title)),staticClass:"subgroup"},[_c('v-list-group',{class:{
                      'arrow-none': item.title || item.svg
                    },attrs:{"href":subItem.url,"group":'listener/citizen',"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-tile',[_c('v-list-tile-content',{class:{
                            'align-non-subitem': !(
                              subItem.action || subItem.svg
                            )
                          }},[_c('v-list-tile-title',{class:{
                              'title-non-subitem': !(
                                subItem.action || subItem.svg
                              )
                            }},[_vm._v(_vm._s(subItem.title))])],1)],1)]},proxy:true}],null,true),model:{value:(subItem.inactive),callback:function ($$v) {_vm.$set(subItem, "inactive", $$v)},expression:"subItem.inactive"}},[_vm._v(" "),_vm._l((subItem.items),function(subItemItem,j){return _c('v-list-tile',{key:(j + "-" + (subItemItem.title)),attrs:{"href":subItemItem.url,"to":subItemItem.to,"target":subItemItem.to ? '_self' : '_blank',"exact":""}},[_c('v-list-tile-action',{class:{
                          'action-subitem':
                            subItemItem.action || subItemItem.svg,
                          'action-non-subitem': !(
                            subItemItem.action || subItemItem.svg
                          ),
                          primary: _vm.$route.fullPath.includes(subItemItem.to)
                            ? true
                            : false
                        }},[(subItemItem.action)?_c('v-icon',[_vm._v(_vm._s(subItemItem.action))]):_vm._e(),_vm._v(" "),(subItemItem.svg)?_c('v-img',{staticClass:"white-icon",attrs:{"src":require(("@/assets/svgs/" + (subItemItem.svg))),"contain":true,"height":"20px","width":"20px"}}):_vm._e()],1),_vm._v(" "),_c('v-list-tile-content',{class:{
                          'align-non-subitem': !(
                            subItemItem.action || subItemItem.svg
                          )
                        }},[_c('v-list-tile-title',{class:{
                            'title-non-subitem': !(
                              subItemItem.action || subItemItem.svg
                            )
                          }},[_vm._v("\n                          "+_vm._s(subItemItem.title)+"\n                        ")])],1)],1)})],2)],1)]:[_c('v-list-tile',{key:(j + "-" + (subItem.title)),attrs:{"href":subItem.url,"to":subItem.to,"target":subItem.to ? '_self' : '_blank',"exact":""}},[_c('v-list-tile-action',{class:{
                      'action-subitem': subItem.action || subItem.svg,
                      'action-non-subitem': !(subItem.action || subItem.svg),
                      primary: _vm.$route.fullPath.includes(subItem.to)
                        ? true
                        : false
                    }},[(subItem.action)?_c('v-icon',[_vm._v(_vm._s(subItem.action))]):_vm._e(),_vm._v(" "),(subItem.svg)?_c('v-img',{staticClass:"white-icon",attrs:{"src":require(("@/assets/svgs/" + (subItem.svg))),"contain":true,"height":"20px","width":"20px"}}):_vm._e()],1),_vm._v(" "),_c('v-list-tile-content',{class:{
                      'align-non-subitem': !(subItem.action || subItem.svg)
                    }},[_c('v-list-tile-title',{class:{
                        'title-non-subitem': !(subItem.action || subItem.svg)
                      }},[_vm._v("\n                      "+_vm._s(subItem.title)+"\n                    ")])],1)],1)]]})],2)}):_vm._e(),_vm._v(" "),(_vm.setItemsPublic.length > 0)?_vm._l((_vm.setItemsPublic),function(item,i){return _c('v-list-group',{key:(i + "-" + (item.title)),class:{
              'arrow-none': item.title || _vm.subItem.svg
            },attrs:{"group":item.group,"no-action":""}},[_c('v-list-tile',{staticClass:"main-menu-item",attrs:{"slot":"activator"},slot:"activator"},[(item.action)?_c('v-icon',{staticClass:"ml-3 mr-3"},[_vm._v("\n                "+_vm._s(item.action)+"\n              ")]):_vm._e(),_vm._v(" "),(item.svg)?_c('v-img',{attrs:{"src":require(("@/assets/svgs/" + (item.svg))),"contain":true,"height":"30px","width":"30px"}}):_vm._e(),_vm._v(" "),_c('v-list-tile-title',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.title))])],1),_vm._v(" "),_vm._l((item.items),function(subItem,j){return _c('v-list-tile',{key:(j + "-" + (subItem.title)),attrs:{"to":subItem.path,"exact":""}},[_c('v-list-tile-action',{class:{
                  'action-subitem': subItem.action || subItem.svg,
                  primary:
                    (subItem.path === _vm.$route.path &&
                      (subItem.action || subItem.svg)) ||
                    (_vm.$route.fullPath === subItem.path + '/' + _vm.$route.params.id
                      ? true
                      : false)
                }},[(subItem.action)?_c('v-icon',[_vm._v(_vm._s(subItem.action))]):_vm._e(),_vm._v(" "),(subItem.svg)?_c('v-img',{attrs:{"src":require(("@/assets/svgs/" + (subItem.svg))),"contain":true,"height":"20px","width":"20px"}}):_vm._e()],1),_vm._v(" "),_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(subItem.title))])],1)],1)})],2)}):_vm._e(),_vm._v(" "),(_vm.itemsCustomMenu.length > 0)?_vm._l((_vm.itemsCustomMenu),function(item,i){return _c('v-list-group',{key:(i + "-" + (item.title)),class:{
              'arrow-none': item.title || _vm.subItem.svg
            },attrs:{"group":item.group,"no-action":""}},[_c('v-list-tile',{staticClass:"main-menu-item",attrs:{"slot":"activator"},slot:"activator"},[(item.action)?_c('v-icon',{staticClass:"ml-3 mr-3"},[_vm._v("\n                "+_vm._s(item.action)+"\n              ")]):_vm._e(),_vm._v(" "),(item.svg)?_c('v-img',{staticClass:"ml-3 mr-3",attrs:{"src":require(("@/assets/svgs/" + (item.svg))),"contain":true,"height":"30px","width":"30px"}}):_vm._e(),_vm._v(" "),_c('v-list-tile-title',{staticClass:"text-xs-left"},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])],1),_vm._v(" "),_vm._l((item.items),function(subItem,j){return _c('v-list-tile',{key:(j + "-" + (subItem.title)),attrs:{"href":subItem.url,"target":"_blank","exact":""}},[_c('v-list-tile-action',{class:{
                  'action-subitem': subItem.action || subItem.svg,
                  'action-non-subitem': !(subItem.action || subItem.svg),
                  primary:
                    subItem.to === _vm.$route.path &&
                    (subItem.action || subItem.svg)
                }},[(subItem.action)?_c('v-icon',[_vm._v(_vm._s(subItem.action))]):_vm._e(),_vm._v(" "),(subItem.svg)?_c('v-img',{staticClass:"white-icon",attrs:{"src":require(("@/assets/svgs/" + (subItem.svg))),"contain":true,"height":"20px","width":"20px"}}):_vm._e()],1),_vm._v(" "),_c('v-list-tile-content',{class:{
                  'align-non-subitem': !(subItem.action || subItem.svg)
                }},[_c('v-list-tile-title',{class:{
                    'title-non-subitem': !(subItem.action || subItem.svg)
                  }},[_vm._v("\n                  "+_vm._s(subItem.title)+"\n                ")])],1)],1)})],2)}):_vm._e()],2),_vm._v(" "),_c('v-divider')]:_vm._e(),_vm._v(" "),(!_vm.$auth.loggedIn)?[_c('v-list-tile',{attrs:{"to":"login"}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("input")])],1),_vm._v(" "),_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Inicia sesión")])],1)],1)]:_vm._e(),_vm._v(" "),(_vm.$auth.loggedIn)?[_c('v-list-tile',{staticClass:"header-background close-session",attrs:{"data-cy":"close-session"},on:{"click":_vm.onLogout}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("exit_to_app")])],1),_vm._v(" "),_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Cerrar sesión")])],1)],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }