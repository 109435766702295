import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _75a49dfe = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _a038d7d4 = () => interopDefault(import('../pages/email-action-handler.vue' /* webpackChunkName: "pages/email-action-handler" */))
const _ac5c7052 = () => interopDefault(import('../pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _3e989372 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _a0fa021c = () => interopDefault(import('../pages/not-validated.vue' /* webpackChunkName: "pages/not-validated" */))
const _747675af = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _665ead34 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7dcc1707 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _e0cc2d96 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _4b2f9211 = () => interopDefault(import('../pages/admin/certificates/index.vue' /* webpackChunkName: "pages/admin/certificates/index" */))
const _3fac149c = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _170c1330 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2c6a397d = () => interopDefault(import('../pages/admin/ai/language.vue' /* webpackChunkName: "pages/admin/ai/language" */))
const _7176b94d = () => interopDefault(import('../pages/admin/ai/vision.vue' /* webpackChunkName: "pages/admin/ai/vision" */))
const _43b66c60 = () => interopDefault(import('../pages/admin/certificates/analytics-extractos/index.vue' /* webpackChunkName: "pages/admin/certificates/analytics-extractos/index" */))
const _5d73c27a = () => interopDefault(import('../pages/admin/certificates/analytics-ganadores/index.vue' /* webpackChunkName: "pages/admin/certificates/analytics-ganadores/index" */))
const _51857e9a = () => interopDefault(import('../pages/admin/certificates/analytics-minibingo/index.vue' /* webpackChunkName: "pages/admin/certificates/analytics-minibingo/index" */))
const _961c26ee = () => interopDefault(import('../pages/admin/certificates/analytics-unificada/index.vue' /* webpackChunkName: "pages/admin/certificates/analytics-unificada/index" */))
const _dd2f81f6 = () => interopDefault(import('../pages/admin/certificates/minibingo-rio-negro/index.vue' /* webpackChunkName: "pages/admin/certificates/minibingo-rio-negro/index" */))
const _c2ba9a4c = () => interopDefault(import('../pages/admin/certificates/rio-negro/index.vue' /* webpackChunkName: "pages/admin/certificates/rio-negro/index" */))
const _18b0975b = () => interopDefault(import('../pages/admin/certificates/unificada-rio-negro/index.vue' /* webpackChunkName: "pages/admin/certificates/unificada-rio-negro/index" */))
const _c18e482e = () => interopDefault(import('../pages/admin/certificates/win-rio-negro/index.vue' /* webpackChunkName: "pages/admin/certificates/win-rio-negro/index" */))
const _61871f89 = () => interopDefault(import('../pages/admin/configurations/areas/index.vue' /* webpackChunkName: "pages/admin/configurations/areas/index" */))
const _a815476c = () => interopDefault(import('../pages/admin/configurations/brand/index.vue' /* webpackChunkName: "pages/admin/configurations/brand/index" */))
const _d5b0035c = () => interopDefault(import('../pages/admin/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/configurations/menu/index" */))
const _1fd13e4a = () => interopDefault(import('../pages/admin/configurations/modules/index.vue' /* webpackChunkName: "pages/admin/configurations/modules/index" */))
const _57db9400 = () => interopDefault(import('../pages/admin/configurations/roles/index.vue' /* webpackChunkName: "pages/admin/configurations/roles/index" */))
const _7b40f416 = () => interopDefault(import('../pages/admin/configurations/settings/index.vue' /* webpackChunkName: "pages/admin/configurations/settings/index" */))
const _3537e164 = () => interopDefault(import('../pages/admin/configurations/urbanism/index.vue' /* webpackChunkName: "pages/admin/configurations/urbanism/index" */))
const _69559cea = () => interopDefault(import('../pages/admin/configurations/users/index.vue' /* webpackChunkName: "pages/admin/configurations/users/index" */))
const _4d739998 = () => interopDefault(import('../pages/admin/listener/attention/index.vue' /* webpackChunkName: "pages/admin/listener/attention/index" */))
const _9b2e5eb2 = () => interopDefault(import('../pages/admin/listener/citizens/index.vue' /* webpackChunkName: "pages/admin/listener/citizens/index" */))
const _7e8cd83c = () => interopDefault(import('../pages/admin/listener/complaint.vue' /* webpackChunkName: "pages/admin/listener/complaint" */))
const _b3caf0e4 = () => interopDefault(import('../pages/admin/listener/dashboard/index.vue' /* webpackChunkName: "pages/admin/listener/dashboard/index" */))
const _2abe8197 = () => interopDefault(import('../pages/admin/listener/new.vue' /* webpackChunkName: "pages/admin/listener/new" */))
const _aa5b0eba = () => interopDefault(import('../pages/admin/listener/publicservices/index.vue' /* webpackChunkName: "pages/admin/listener/publicservices/index" */))
const _f6a61ff6 = () => interopDefault(import('../pages/admin/transparency/initiatives/index.vue' /* webpackChunkName: "pages/admin/transparency/initiatives/index" */))
const _517fb38c = () => interopDefault(import('../pages/admin/transparency/map/index.vue' /* webpackChunkName: "pages/admin/transparency/map/index" */))
const _847ac430 = () => interopDefault(import('../pages/admin/urbanism/obras/index.vue' /* webpackChunkName: "pages/admin/urbanism/obras/index" */))
const _dca28e2c = () => interopDefault(import('../pages/citizen/listener/create-v1.vue' /* webpackChunkName: "pages/citizen/listener/create-v1" */))
const _dc865f2a = () => interopDefault(import('../pages/citizen/listener/create-v2.vue' /* webpackChunkName: "pages/citizen/listener/create-v2" */))
const _dc6a3028 = () => interopDefault(import('../pages/citizen/listener/create-v3.vue' /* webpackChunkName: "pages/citizen/listener/create-v3" */))
const _ed4cb5e0 = () => interopDefault(import('../pages/citizen/listener/list.vue' /* webpackChunkName: "pages/citizen/listener/list" */))
const _4e5ff44c = () => interopDefault(import('../pages/citizen/listener/map.vue' /* webpackChunkName: "pages/citizen/listener/map" */))
const _3d75da13 = () => interopDefault(import('../pages/citizen/listener/my-reports/index.vue' /* webpackChunkName: "pages/citizen/listener/my-reports/index" */))
const _5a0ec2cc = () => interopDefault(import('../pages/citizen/transparency/initiatives/index.vue' /* webpackChunkName: "pages/citizen/transparency/initiatives/index" */))
const _45009ffe = () => interopDefault(import('../pages/citizen/transparency/map.vue' /* webpackChunkName: "pages/citizen/transparency/map" */))
const _48e0ed6f = () => interopDefault(import('../pages/citizen/urbanism/obras/index.vue' /* webpackChunkName: "pages/citizen/urbanism/obras/index" */))
const _7291c41a = () => interopDefault(import('../pages/admin/certificates/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/certificates/configurations/menu/index" */))
const _1d31d7a6 = () => interopDefault(import('../pages/admin/configurations/areas/area.vue' /* webpackChunkName: "pages/admin/configurations/areas/area" */))
const _742a2ce2 = () => interopDefault(import('../pages/admin/configurations/modules/menu-certificates.vue' /* webpackChunkName: "pages/admin/configurations/modules/menu-certificates" */))
const _1a5b4f04 = () => interopDefault(import('../pages/admin/configurations/modules/menu-transparency.vue' /* webpackChunkName: "pages/admin/configurations/modules/menu-transparency" */))
const _84833ad2 = () => interopDefault(import('../pages/admin/configurations/modules/menu-urbanism.vue' /* webpackChunkName: "pages/admin/configurations/modules/menu-urbanism" */))
const _42def530 = () => interopDefault(import('../pages/admin/configurations/urbanism/edit.vue' /* webpackChunkName: "pages/admin/configurations/urbanism/edit" */))
const _352dc724 = () => interopDefault(import('../pages/admin/listener/attention/create-activities.vue' /* webpackChunkName: "pages/admin/listener/attention/create-activities" */))
const _24b40911 = () => interopDefault(import('../pages/admin/listener/attention/create-institution.vue' /* webpackChunkName: "pages/admin/listener/attention/create-institution" */))
const _1430f476 = () => interopDefault(import('../pages/admin/listener/attention/create-materials.vue' /* webpackChunkName: "pages/admin/listener/attention/create-materials" */))
const _e2db5eae = () => interopDefault(import('../pages/admin/listener/attention/create-streets.vue' /* webpackChunkName: "pages/admin/listener/attention/create-streets" */))
const _36921ba8 = () => interopDefault(import('../pages/admin/listener/configurations/categories/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/categories/index" */))
const _52c01beb = () => interopDefault(import('../pages/admin/listener/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/menu/index" */))
const _288479bf = () => interopDefault(import('../pages/admin/listener/configurations/organization/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/index" */))
const _2b7db347 = () => interopDefault(import('../pages/admin/listener/configurations/trackers/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/trackers/index" */))
const _e3dd028c = () => interopDefault(import('../pages/admin/listener/socialnetworks/facebook.vue' /* webpackChunkName: "pages/admin/listener/socialnetworks/facebook" */))
const _3517ebaf = () => interopDefault(import('../pages/admin/listener/socialnetworks/twitter.vue' /* webpackChunkName: "pages/admin/listener/socialnetworks/twitter" */))
const _78a562ff = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/index" */))
const _4e95be8f = () => interopDefault(import('../pages/admin/transparency/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/menu/index" */))
const _f4d529d8 = () => interopDefault(import('../pages/admin/transparency/initiatives/add.vue' /* webpackChunkName: "pages/admin/transparency/initiatives/add" */))
const _070aaaee = () => interopDefault(import('../pages/admin/transparency/map/add.vue' /* webpackChunkName: "pages/admin/transparency/map/add" */))
const _5f783d30 = () => interopDefault(import('../pages/admin/urbanism/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/urbanism/configurations/menu/index" */))
const _c2199492 = () => interopDefault(import('../pages/admin/urbanism/obras/add.vue' /* webpackChunkName: "pages/admin/urbanism/obras/add" */))
const _8ee83356 = () => interopDefault(import('../pages/admin/urbanism/obras/create-neighborhood.vue' /* webpackChunkName: "pages/admin/urbanism/obras/create-neighborhood" */))
const _5637d84e = () => interopDefault(import('../pages/admin/listener/configurations/organization/citizenattention.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/citizenattention" */))
const _922cfaca = () => interopDefault(import('../pages/admin/listener/attention/conformities/_id.vue' /* webpackChunkName: "pages/admin/listener/attention/conformities/_id" */))
const _3573da1e = () => interopDefault(import('../pages/admin/listener/attention/print/_id.vue' /* webpackChunkName: "pages/admin/listener/attention/print/_id" */))
const _cbb5bada = () => interopDefault(import('../pages/admin/listener/configurations/categories/_id/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/categories/_id/index" */))
const _0dd164c2 = () => interopDefault(import('../pages/admin/listener/configurations/organization/_secretary/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/_secretary/index" */))
const _eff2332c = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/_id/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/_id/index" */))
const _3577c7eb = () => interopDefault(import('../pages/admin/listener/configurations/categories/_id/_subid.vue' /* webpackChunkName: "pages/admin/listener/configurations/categories/_id/_subid" */))
const _cadbed18 = () => interopDefault(import('../pages/admin/listener/configurations/organization/_secretary/_agency/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/_secretary/_agency/index" */))
const _41b4fe77 = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/_id/_subid/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/_id/_subid/index" */))
const _239cfbe8 = () => interopDefault(import('../pages/admin/listener/configurations/organization/_secretary/_agency/_operator/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/_secretary/_agency/_operator/index" */))
const _3cad2a64 = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/_id/_subid/_deepid/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/_id/_subid/_deepid/index" */))
const _7d3e9668 = () => interopDefault(import('../pages/admin/configurations/roles/_id.vue' /* webpackChunkName: "pages/admin/configurations/roles/_id" */))
const _85efca9a = () => interopDefault(import('../pages/admin/configurations/users/_id.vue' /* webpackChunkName: "pages/admin/configurations/users/_id" */))
const _3869bc00 = () => interopDefault(import('../pages/admin/listener/attention/_id.vue' /* webpackChunkName: "pages/admin/listener/attention/_id" */))
const _24512fad = () => interopDefault(import('../pages/admin/transparency/initiatives/_id.vue' /* webpackChunkName: "pages/admin/transparency/initiatives/_id" */))
const _c99321bc = () => interopDefault(import('../pages/admin/transparency/map/_id.vue' /* webpackChunkName: "pages/admin/transparency/map/_id" */))
const _3daefa50 = () => interopDefault(import('../pages/admin/urbanism/obras/_id.vue' /* webpackChunkName: "pages/admin/urbanism/obras/_id" */))
const _ca6d758a = () => interopDefault(import('../pages/citizen/listener/my-reports/_id.vue' /* webpackChunkName: "pages/citizen/listener/my-reports/_id" */))
const _cd3e11a8 = () => interopDefault(import('../pages/citizen/listener/success/_id.vue' /* webpackChunkName: "pages/citizen/listener/success/_id" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _75a49dfe,
    name: "contact___es"
  }, {
    path: "/email-action-handler",
    component: _a038d7d4,
    name: "email-action-handler___es"
  }, {
    path: "/forbidden",
    component: _ac5c7052,
    name: "forbidden___es"
  }, {
    path: "/login",
    component: _3e989372,
    name: "login___es"
  }, {
    path: "/not-validated",
    component: _a0fa021c,
    name: "not-validated___es"
  }, {
    path: "/password-reset",
    component: _747675af,
    name: "password-reset___es"
  }, {
    path: "/privacy",
    component: _665ead34,
    name: "privacy___es"
  }, {
    path: "/profile",
    component: _7dcc1707,
    name: "profile___es"
  }, {
    path: "/register",
    component: _e0cc2d96,
    name: "register___es"
  }, {
    path: "/admin/certificates",
    component: _4b2f9211,
    name: "admin-certificates___es"
  }, {
    path: "/admin/dashboard",
    component: _3fac149c,
    name: "admin-dashboard___es"
  }, {
    path: "/en/",
    component: _170c1330,
    name: "index___en"
  }, {
    path: "/en/contact",
    component: _75a49dfe,
    name: "contact___en"
  }, {
    path: "/en/email-action-handler",
    component: _a038d7d4,
    name: "email-action-handler___en"
  }, {
    path: "/en/forbidden",
    component: _ac5c7052,
    name: "forbidden___en"
  }, {
    path: "/en/login",
    component: _3e989372,
    name: "login___en"
  }, {
    path: "/en/not-validated",
    component: _a0fa021c,
    name: "not-validated___en"
  }, {
    path: "/en/password-reset",
    component: _747675af,
    name: "password-reset___en"
  }, {
    path: "/en/privacy",
    component: _665ead34,
    name: "privacy___en"
  }, {
    path: "/en/profile",
    component: _7dcc1707,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _e0cc2d96,
    name: "register___en"
  }, {
    path: "/admin/ai/language",
    component: _2c6a397d,
    name: "admin-ai-language___es"
  }, {
    path: "/admin/ai/vision",
    component: _7176b94d,
    name: "admin-ai-vision___es"
  }, {
    path: "/admin/certificates/analytics-extractos",
    component: _43b66c60,
    name: "admin-certificates-analytics-extractos___es"
  }, {
    path: "/admin/certificates/analytics-ganadores",
    component: _5d73c27a,
    name: "admin-certificates-analytics-ganadores___es"
  }, {
    path: "/admin/certificates/analytics-minibingo",
    component: _51857e9a,
    name: "admin-certificates-analytics-minibingo___es"
  }, {
    path: "/admin/certificates/analytics-unificada",
    component: _961c26ee,
    name: "admin-certificates-analytics-unificada___es"
  }, {
    path: "/admin/certificates/minibingo-rio-negro",
    component: _dd2f81f6,
    name: "admin-certificates-minibingo-rio-negro___es"
  }, {
    path: "/admin/certificates/rio-negro",
    component: _c2ba9a4c,
    name: "admin-certificates-rio-negro___es"
  }, {
    path: "/admin/certificates/unificada-rio-negro",
    component: _18b0975b,
    name: "admin-certificates-unificada-rio-negro___es"
  }, {
    path: "/admin/certificates/win-rio-negro",
    component: _c18e482e,
    name: "admin-certificates-win-rio-negro___es"
  }, {
    path: "/admin/configurations/areas",
    component: _61871f89,
    name: "admin-configurations-areas___es"
  }, {
    path: "/admin/configurations/brand",
    component: _a815476c,
    name: "admin-configurations-brand___es"
  }, {
    path: "/admin/configurations/menu",
    component: _d5b0035c,
    name: "admin-configurations-menu___es"
  }, {
    path: "/admin/configurations/modules",
    component: _1fd13e4a,
    name: "admin-configurations-modules___es"
  }, {
    path: "/admin/configurations/roles",
    component: _57db9400,
    name: "admin-configurations-roles___es"
  }, {
    path: "/admin/configurations/settings",
    component: _7b40f416,
    name: "admin-configurations-settings___es"
  }, {
    path: "/admin/configurations/urbanism",
    component: _3537e164,
    name: "admin-configurations-urbanism___es"
  }, {
    path: "/admin/configurations/users",
    component: _69559cea,
    name: "admin-configurations-users___es"
  }, {
    path: "/admin/listener/attention",
    component: _4d739998,
    name: "admin-listener-attention___es"
  }, {
    path: "/admin/listener/citizens",
    component: _9b2e5eb2,
    name: "admin-listener-citizens___es"
  }, {
    path: "/admin/listener/complaint",
    component: _7e8cd83c,
    name: "admin-listener-complaint___es"
  }, {
    path: "/admin/listener/dashboard",
    component: _b3caf0e4,
    name: "admin-listener-dashboard___es"
  }, {
    path: "/admin/listener/new",
    component: _2abe8197,
    name: "admin-listener-new___es"
  }, {
    path: "/admin/listener/publicservices",
    component: _aa5b0eba,
    name: "admin-listener-publicservices___es"
  }, {
    path: "/admin/transparency/initiatives",
    component: _f6a61ff6,
    name: "admin-transparency-initiatives___es"
  }, {
    path: "/admin/transparency/map",
    component: _517fb38c,
    name: "admin-transparency-map___es"
  }, {
    path: "/admin/urbanism/obras",
    component: _847ac430,
    name: "admin-urbanism-obras___es"
  }, {
    path: "/citizen/listener/create-v1",
    component: _dca28e2c,
    name: "citizen-listener-create-v1___es"
  }, {
    path: "/citizen/listener/create-v2",
    component: _dc865f2a,
    name: "citizen-listener-create-v2___es"
  }, {
    path: "/citizen/listener/create-v3",
    component: _dc6a3028,
    name: "citizen-listener-create-v3___es"
  }, {
    path: "/citizen/listener/list",
    component: _ed4cb5e0,
    name: "citizen-listener-list___es"
  }, {
    path: "/citizen/listener/map",
    component: _4e5ff44c,
    name: "citizen-listener-map___es"
  }, {
    path: "/citizen/listener/my-reports",
    component: _3d75da13,
    name: "citizen-listener-my-reports___es"
  }, {
    path: "/citizen/transparency/initiatives",
    component: _5a0ec2cc,
    name: "citizen-transparency-initiatives___es"
  }, {
    path: "/citizen/transparency/map",
    component: _45009ffe,
    name: "citizen-transparency-map___es"
  }, {
    path: "/citizen/urbanism/obras",
    component: _48e0ed6f,
    name: "citizen-urbanism-obras___es"
  }, {
    path: "/en/admin/certificates",
    component: _4b2f9211,
    name: "admin-certificates___en"
  }, {
    path: "/en/admin/dashboard",
    component: _3fac149c,
    name: "admin-dashboard___en"
  }, {
    path: "/admin/certificates/configurations/menu",
    component: _7291c41a,
    name: "admin-certificates-configurations-menu___es"
  }, {
    path: "/admin/configurations/areas/area",
    component: _1d31d7a6,
    name: "admin-configurations-areas-area___es"
  }, {
    path: "/admin/configurations/modules/menu-certificates",
    component: _742a2ce2,
    name: "admin-configurations-modules-menu-certificates___es"
  }, {
    path: "/admin/configurations/modules/menu-transparency",
    component: _1a5b4f04,
    name: "admin-configurations-modules-menu-transparency___es"
  }, {
    path: "/admin/configurations/modules/menu-urbanism",
    component: _84833ad2,
    name: "admin-configurations-modules-menu-urbanism___es"
  }, {
    path: "/admin/configurations/urbanism/edit",
    component: _42def530,
    name: "admin-configurations-urbanism-edit___es"
  }, {
    path: "/admin/listener/attention/create-activities",
    component: _352dc724,
    name: "admin-listener-attention-create-activities___es"
  }, {
    path: "/admin/listener/attention/create-institution",
    component: _24b40911,
    name: "admin-listener-attention-create-institution___es"
  }, {
    path: "/admin/listener/attention/create-materials",
    component: _1430f476,
    name: "admin-listener-attention-create-materials___es"
  }, {
    path: "/admin/listener/attention/create-streets",
    component: _e2db5eae,
    name: "admin-listener-attention-create-streets___es"
  }, {
    path: "/admin/listener/configurations/categories",
    component: _36921ba8,
    name: "admin-listener-configurations-categories___es"
  }, {
    path: "/admin/listener/configurations/menu",
    component: _52c01beb,
    name: "admin-listener-configurations-menu___es"
  }, {
    path: "/admin/listener/configurations/organization",
    component: _288479bf,
    name: "admin-listener-configurations-organization___es"
  }, {
    path: "/admin/listener/configurations/trackers",
    component: _2b7db347,
    name: "admin-listener-configurations-trackers___es"
  }, {
    path: "/admin/listener/socialnetworks/facebook",
    component: _e3dd028c,
    name: "admin-listener-socialnetworks-facebook___es"
  }, {
    path: "/admin/listener/socialnetworks/twitter",
    component: _3517ebaf,
    name: "admin-listener-socialnetworks-twitter___es"
  }, {
    path: "/admin/transparency/configurations/catalog",
    component: _78a562ff,
    name: "admin-transparency-configurations-catalog___es"
  }, {
    path: "/admin/transparency/configurations/menu",
    component: _4e95be8f,
    name: "admin-transparency-configurations-menu___es"
  }, {
    path: "/admin/transparency/initiatives/add",
    component: _f4d529d8,
    name: "admin-transparency-initiatives-add___es"
  }, {
    path: "/admin/transparency/map/add",
    component: _070aaaee,
    name: "admin-transparency-map-add___es"
  }, {
    path: "/admin/urbanism/configurations/menu",
    component: _5f783d30,
    name: "admin-urbanism-configurations-menu___es"
  }, {
    path: "/admin/urbanism/obras/add",
    component: _c2199492,
    name: "admin-urbanism-obras-add___es"
  }, {
    path: "/admin/urbanism/obras/create-neighborhood",
    component: _8ee83356,
    name: "admin-urbanism-obras-create-neighborhood___es"
  }, {
    path: "/en/admin/ai/language",
    component: _2c6a397d,
    name: "admin-ai-language___en"
  }, {
    path: "/en/admin/ai/vision",
    component: _7176b94d,
    name: "admin-ai-vision___en"
  }, {
    path: "/en/admin/certificates/analytics-extractos",
    component: _43b66c60,
    name: "admin-certificates-analytics-extractos___en"
  }, {
    path: "/en/admin/certificates/analytics-ganadores",
    component: _5d73c27a,
    name: "admin-certificates-analytics-ganadores___en"
  }, {
    path: "/en/admin/certificates/analytics-minibingo",
    component: _51857e9a,
    name: "admin-certificates-analytics-minibingo___en"
  }, {
    path: "/en/admin/certificates/analytics-unificada",
    component: _961c26ee,
    name: "admin-certificates-analytics-unificada___en"
  }, {
    path: "/en/admin/certificates/minibingo-rio-negro",
    component: _dd2f81f6,
    name: "admin-certificates-minibingo-rio-negro___en"
  }, {
    path: "/en/admin/certificates/rio-negro",
    component: _c2ba9a4c,
    name: "admin-certificates-rio-negro___en"
  }, {
    path: "/en/admin/certificates/unificada-rio-negro",
    component: _18b0975b,
    name: "admin-certificates-unificada-rio-negro___en"
  }, {
    path: "/en/admin/certificates/win-rio-negro",
    component: _c18e482e,
    name: "admin-certificates-win-rio-negro___en"
  }, {
    path: "/en/admin/configurations/areas",
    component: _61871f89,
    name: "admin-configurations-areas___en"
  }, {
    path: "/en/admin/configurations/brand",
    component: _a815476c,
    name: "admin-configurations-brand___en"
  }, {
    path: "/en/admin/configurations/menu",
    component: _d5b0035c,
    name: "admin-configurations-menu___en"
  }, {
    path: "/en/admin/configurations/modules",
    component: _1fd13e4a,
    name: "admin-configurations-modules___en"
  }, {
    path: "/en/admin/configurations/roles",
    component: _57db9400,
    name: "admin-configurations-roles___en"
  }, {
    path: "/en/admin/configurations/settings",
    component: _7b40f416,
    name: "admin-configurations-settings___en"
  }, {
    path: "/en/admin/configurations/urbanism",
    component: _3537e164,
    name: "admin-configurations-urbanism___en"
  }, {
    path: "/en/admin/configurations/users",
    component: _69559cea,
    name: "admin-configurations-users___en"
  }, {
    path: "/en/admin/listener/attention",
    component: _4d739998,
    name: "admin-listener-attention___en"
  }, {
    path: "/en/admin/listener/citizens",
    component: _9b2e5eb2,
    name: "admin-listener-citizens___en"
  }, {
    path: "/en/admin/listener/complaint",
    component: _7e8cd83c,
    name: "admin-listener-complaint___en"
  }, {
    path: "/en/admin/listener/dashboard",
    component: _b3caf0e4,
    name: "admin-listener-dashboard___en"
  }, {
    path: "/en/admin/listener/new",
    component: _2abe8197,
    name: "admin-listener-new___en"
  }, {
    path: "/en/admin/listener/publicservices",
    component: _aa5b0eba,
    name: "admin-listener-publicservices___en"
  }, {
    path: "/en/admin/transparency/initiatives",
    component: _f6a61ff6,
    name: "admin-transparency-initiatives___en"
  }, {
    path: "/en/admin/transparency/map",
    component: _517fb38c,
    name: "admin-transparency-map___en"
  }, {
    path: "/en/admin/urbanism/obras",
    component: _847ac430,
    name: "admin-urbanism-obras___en"
  }, {
    path: "/en/citizen/listener/create-v1",
    component: _dca28e2c,
    name: "citizen-listener-create-v1___en"
  }, {
    path: "/en/citizen/listener/create-v2",
    component: _dc865f2a,
    name: "citizen-listener-create-v2___en"
  }, {
    path: "/en/citizen/listener/create-v3",
    component: _dc6a3028,
    name: "citizen-listener-create-v3___en"
  }, {
    path: "/en/citizen/listener/list",
    component: _ed4cb5e0,
    name: "citizen-listener-list___en"
  }, {
    path: "/en/citizen/listener/map",
    component: _4e5ff44c,
    name: "citizen-listener-map___en"
  }, {
    path: "/en/citizen/listener/my-reports",
    component: _3d75da13,
    name: "citizen-listener-my-reports___en"
  }, {
    path: "/en/citizen/transparency/initiatives",
    component: _5a0ec2cc,
    name: "citizen-transparency-initiatives___en"
  }, {
    path: "/en/citizen/transparency/map",
    component: _45009ffe,
    name: "citizen-transparency-map___en"
  }, {
    path: "/en/citizen/urbanism/obras",
    component: _48e0ed6f,
    name: "citizen-urbanism-obras___en"
  }, {
    path: "/admin/listener/configurations/organization/citizenattention",
    component: _5637d84e,
    name: "admin-listener-configurations-organization-citizenattention___es"
  }, {
    path: "/en/admin/certificates/configurations/menu",
    component: _7291c41a,
    name: "admin-certificates-configurations-menu___en"
  }, {
    path: "/en/admin/configurations/areas/area",
    component: _1d31d7a6,
    name: "admin-configurations-areas-area___en"
  }, {
    path: "/en/admin/configurations/modules/menu-certificates",
    component: _742a2ce2,
    name: "admin-configurations-modules-menu-certificates___en"
  }, {
    path: "/en/admin/configurations/modules/menu-transparency",
    component: _1a5b4f04,
    name: "admin-configurations-modules-menu-transparency___en"
  }, {
    path: "/en/admin/configurations/modules/menu-urbanism",
    component: _84833ad2,
    name: "admin-configurations-modules-menu-urbanism___en"
  }, {
    path: "/en/admin/configurations/urbanism/edit",
    component: _42def530,
    name: "admin-configurations-urbanism-edit___en"
  }, {
    path: "/en/admin/listener/attention/create-activities",
    component: _352dc724,
    name: "admin-listener-attention-create-activities___en"
  }, {
    path: "/en/admin/listener/attention/create-institution",
    component: _24b40911,
    name: "admin-listener-attention-create-institution___en"
  }, {
    path: "/en/admin/listener/attention/create-materials",
    component: _1430f476,
    name: "admin-listener-attention-create-materials___en"
  }, {
    path: "/en/admin/listener/attention/create-streets",
    component: _e2db5eae,
    name: "admin-listener-attention-create-streets___en"
  }, {
    path: "/en/admin/listener/configurations/categories",
    component: _36921ba8,
    name: "admin-listener-configurations-categories___en"
  }, {
    path: "/en/admin/listener/configurations/menu",
    component: _52c01beb,
    name: "admin-listener-configurations-menu___en"
  }, {
    path: "/en/admin/listener/configurations/organization",
    component: _288479bf,
    name: "admin-listener-configurations-organization___en"
  }, {
    path: "/en/admin/listener/configurations/trackers",
    component: _2b7db347,
    name: "admin-listener-configurations-trackers___en"
  }, {
    path: "/en/admin/listener/socialnetworks/facebook",
    component: _e3dd028c,
    name: "admin-listener-socialnetworks-facebook___en"
  }, {
    path: "/en/admin/listener/socialnetworks/twitter",
    component: _3517ebaf,
    name: "admin-listener-socialnetworks-twitter___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog",
    component: _78a562ff,
    name: "admin-transparency-configurations-catalog___en"
  }, {
    path: "/en/admin/transparency/configurations/menu",
    component: _4e95be8f,
    name: "admin-transparency-configurations-menu___en"
  }, {
    path: "/en/admin/transparency/initiatives/add",
    component: _f4d529d8,
    name: "admin-transparency-initiatives-add___en"
  }, {
    path: "/en/admin/transparency/map/add",
    component: _070aaaee,
    name: "admin-transparency-map-add___en"
  }, {
    path: "/en/admin/urbanism/configurations/menu",
    component: _5f783d30,
    name: "admin-urbanism-configurations-menu___en"
  }, {
    path: "/en/admin/urbanism/obras/add",
    component: _c2199492,
    name: "admin-urbanism-obras-add___en"
  }, {
    path: "/en/admin/urbanism/obras/create-neighborhood",
    component: _8ee83356,
    name: "admin-urbanism-obras-create-neighborhood___en"
  }, {
    path: "/en/admin/listener/configurations/organization/citizenattention",
    component: _5637d84e,
    name: "admin-listener-configurations-organization-citizenattention___en"
  }, {
    path: "/en/admin/listener/attention/conformities/:id?",
    component: _922cfaca,
    name: "admin-listener-attention-conformities-id___en"
  }, {
    path: "/en/admin/listener/attention/print/:id?",
    component: _3573da1e,
    name: "admin-listener-attention-print-id___en"
  }, {
    path: "/en/admin/listener/configurations/categories/:id",
    component: _cbb5bada,
    name: "admin-listener-configurations-categories-id___en"
  }, {
    path: "/en/admin/listener/configurations/organization/:secretary",
    component: _0dd164c2,
    name: "admin-listener-configurations-organization-secretary___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog/:id",
    component: _eff2332c,
    name: "admin-transparency-configurations-catalog-id___en"
  }, {
    path: "/en/admin/listener/configurations/categories/:id/:subid",
    component: _3577c7eb,
    name: "admin-listener-configurations-categories-id-subid___en"
  }, {
    path: "/en/admin/listener/configurations/organization/:secretary/:agency",
    component: _cadbed18,
    name: "admin-listener-configurations-organization-secretary-agency___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog/:id/:subid",
    component: _41b4fe77,
    name: "admin-transparency-configurations-catalog-id-subid___en"
  }, {
    path: "/en/admin/listener/configurations/organization/:secretary/:agency/:operator",
    component: _239cfbe8,
    name: "admin-listener-configurations-organization-secretary-agency-operator___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog/:id/:subid/:deepid",
    component: _3cad2a64,
    name: "admin-transparency-configurations-catalog-id-subid-deepid___en"
  }, {
    path: "/admin/listener/attention/conformities/:id?",
    component: _922cfaca,
    name: "admin-listener-attention-conformities-id___es"
  }, {
    path: "/admin/listener/attention/print/:id?",
    component: _3573da1e,
    name: "admin-listener-attention-print-id___es"
  }, {
    path: "/admin/listener/configurations/categories/:id",
    component: _cbb5bada,
    name: "admin-listener-configurations-categories-id___es"
  }, {
    path: "/admin/listener/configurations/organization/:secretary",
    component: _0dd164c2,
    name: "admin-listener-configurations-organization-secretary___es"
  }, {
    path: "/admin/transparency/configurations/catalog/:id",
    component: _eff2332c,
    name: "admin-transparency-configurations-catalog-id___es"
  }, {
    path: "/en/admin/configurations/roles/:id",
    component: _7d3e9668,
    name: "admin-configurations-roles-id___en"
  }, {
    path: "/en/admin/configurations/users/:id",
    component: _85efca9a,
    name: "admin-configurations-users-id___en"
  }, {
    path: "/en/admin/listener/attention/:id",
    component: _3869bc00,
    name: "admin-listener-attention-id___en"
  }, {
    path: "/en/admin/transparency/initiatives/:id",
    component: _24512fad,
    name: "admin-transparency-initiatives-id___en"
  }, {
    path: "/en/admin/transparency/map/:id",
    component: _c99321bc,
    name: "admin-transparency-map-id___en"
  }, {
    path: "/en/admin/urbanism/obras/:id",
    component: _3daefa50,
    name: "admin-urbanism-obras-id___en"
  }, {
    path: "/en/citizen/listener/my-reports/:id?",
    component: _ca6d758a,
    name: "citizen-listener-my-reports-id___en"
  }, {
    path: "/en/citizen/listener/success/:id?",
    component: _cd3e11a8,
    name: "citizen-listener-success-id___en"
  }, {
    path: "/admin/listener/configurations/categories/:id/:subid",
    component: _3577c7eb,
    name: "admin-listener-configurations-categories-id-subid___es"
  }, {
    path: "/admin/listener/configurations/organization/:secretary/:agency",
    component: _cadbed18,
    name: "admin-listener-configurations-organization-secretary-agency___es"
  }, {
    path: "/admin/transparency/configurations/catalog/:id/:subid",
    component: _41b4fe77,
    name: "admin-transparency-configurations-catalog-id-subid___es"
  }, {
    path: "/admin/listener/configurations/organization/:secretary/:agency/:operator",
    component: _239cfbe8,
    name: "admin-listener-configurations-organization-secretary-agency-operator___es"
  }, {
    path: "/admin/transparency/configurations/catalog/:id/:subid/:deepid",
    component: _3cad2a64,
    name: "admin-transparency-configurations-catalog-id-subid-deepid___es"
  }, {
    path: "/admin/configurations/roles/:id",
    component: _7d3e9668,
    name: "admin-configurations-roles-id___es"
  }, {
    path: "/admin/configurations/users/:id",
    component: _85efca9a,
    name: "admin-configurations-users-id___es"
  }, {
    path: "/admin/listener/attention/:id",
    component: _3869bc00,
    name: "admin-listener-attention-id___es"
  }, {
    path: "/admin/transparency/initiatives/:id",
    component: _24512fad,
    name: "admin-transparency-initiatives-id___es"
  }, {
    path: "/admin/transparency/map/:id",
    component: _c99321bc,
    name: "admin-transparency-map-id___es"
  }, {
    path: "/admin/urbanism/obras/:id",
    component: _3daefa50,
    name: "admin-urbanism-obras-id___es"
  }, {
    path: "/citizen/listener/my-reports/:id?",
    component: _ca6d758a,
    name: "citizen-listener-my-reports-id___es"
  }, {
    path: "/citizen/listener/success/:id?",
    component: _cd3e11a8,
    name: "citizen-listener-success-id___es"
  }, {
    path: "/",
    component: _170c1330,
    name: "index___es"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
